footer {
  background-color: var(--color-dkgrey);
  display: block;
  width: 100%;
  padding: 1rem;
  color: var(--color-white);
  text-align: center;
}

footer p {
  margin: 0;
}

@media (min-width: 768px) {
  footer.sticky {
    position: absolute;
    bottom: 0;
  }
}
