.portfolio .card {
  height: 100%;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.3);
  transition: all 0.3s linear;
}

.portfolio .card:hover {
  box-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

.portfolio .card-footer {
  background-color: transparent;
  border-top: 0;
}

.portfolio .card .btn {
  display: block;
  text-align: center;
}
