html {
  height: 100%;
  position: relative;
}

body {
  height: 100%;
  position: relative;
  font-family: var(--font-sans);
  color: var(--color-grey);
}

#root,
main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: var(--color-blue);
  text-decoration: none;
}

a:hover,
a:active {
  color: var(--color-dkorange);
}

.page {
  padding-top: 4rem;
  flex-grow: 1;
}

h1,
h2 {
  font-family: var(--font-serif);
  text-transform: uppercase;
}

.serif {
  font-family: var(--font-serif);
}

.cursive {
  font-family: var(--font-cursive);
}

/*button colors*/
.btn {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--font-serif);
  text-shadow: none;
  box-shadow: 2px 2px 4px var(--color-shadow);
  border-radius: 0;
}
.btn-primary {
  background-color: var(--color-blue);
  border-color: var(--color-blue);
  color: var(--color-white);
}
.btn-primary:focus, .btn-primary:active, .btn-primary:hover {
  background-color: var(--color-dkblue);
  border-color: var(--color-dkblue);
  color: var(--color-white);
}
.btn-orange {
  background-color: var(--color-orange);
  border-color: var(--color-orange);
  color: var(--color-white);
}
.btn-orange:focus, .btn-orange:active, .btn-orange:hover {
  background-color: var(--color-dkorange);
  border-color: var(--color-dkorange);
  color: var(--color-white);
}

/*icons*/
.icon--heading {
  color: var(--color-blue);
}
.icon--orange {
  color: var(--color-orange);
}
