.resume {
  scroll-margin-top: 3rem;
}
.resume--download {
  color: var(--color-dkgrey);
  font-size: 2.2rem;
}
.resume--skills--list {
  padding-left: 3.5rem;
}
.resume--experience--job {
  margin-bottom: 2rem;
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .resume--experience--job {
    margin-left: 3rem;
  }
}
.resume--experience--job--heading {
  font-size: 1.4rem;
  text-transform: uppercase;
}
.resume--experience--job--date {
  color: var(--color-dkgrey);
  font-size: 1.3rem;
  display: block;
}
@media (min-width: 768px) {
  .resume--experience--job--date {
    margin-left: 1rem;
    display: inline;
  }
}
.resume--experience--job--subheading {
  font-family: var(--font-serif);
  font-size: 1rem;
  text-transform: uppercase;
}

.resume h2 {
  font-size: 1.6rem;
}
