* {
  /*colors*/
  --color-orange: #e39527;
  --color-dkorange: #a36105;
  --color-blue: #0c62a4;
  --color-dkblue: #044679;
  --color-ltblue: #4798d5;
  --color-vltblue: #72c2ff;
  --color-dkgrey: #101010;
  --color-grey: #333;
  --color-ltgrey: #b5b5b5;
  --color-xltgrey: #eeeeee;
  --color-white: #fff;
  --color-shadow: rgba(51, 51, 51, 0.304);
  --text-gradient: linear-gradient(to right, #044679 30%, #A36105 60%);

  /*fonts*/
  --font-sans: 'Roboto', sans-serif;
  --font-serif: 'Roboto Serif', serif;
  --font-cursive: 'Dancing Script', cursive;
}
