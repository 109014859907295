.header--logo {
  color: var(--color-white);
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: 2px;
  line-height: 1.8rem;
  text-transform: uppercase;
  font-family: var(--font-serif);
  text-align: center;
  padding: 0.3rem 0;
  text-shadow: 1px 1px var(--color-grey);
}
.header--logo--sub {
  font-size: 1.5rem;
  font-weight: normal;
  color: var(--color-ltgrey);
  font-family: var(--font-sans);
}
.header--icon {
  display: inline-block;
  font-size: 2rem;
  color: var(--color-white);
}
.header--icon:hover, .header--icon:active, .header--icon:focus {
  color: var(--color-ltgrey);
}

.navbar-dark.bg-dark {
  background-color: var(--color-dkgrey) !important;
}

#offcanvasNavbar {
  background-color: var(--color-dkgrey);
  color: var(--color-white);
}

.sticky-top {
  box-shadow: 2px 2px 4px var(--color-shadow);
}
