.refs {
  background-color: var(--color-xltgrey);
}

.references {
  min-height: 450px;
  padding-bottom: 1rem;
}
.references--slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 450px;
}
.references--content {
  text-align: center;
  width: 80%;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .references--content {
    width: 60%;
  }
}
.references--content--img {
  border-radius: 0;
  margin-bottom: 1rem;
}
.references--content--title {
  text-shadow: 1px 1px var(--color-ltgrey);
  font-family: var(--font-serif);
  margin-bottom: 0;
}
.references--content--subtitle {
  font-size: 1rem;
  color: var(--color-grey);
  text-transform: uppercase;
}
.references--content--text {
  position: relative;
  padding: 0 2.2rem;
}
.references--icon {
  font-size: 4rem;
  color: var(--color-orange);
  opacity: 0.3;
  position: absolute;
  filter: drop-shadow(1px 1px var(--color-shadow));
}
.references--icon.left {
  top: -1.5rem;
  left: -0.8rem;
}
.references--icon.right {
  bottom: -0.3rem;
  right: -0.5rem;
  color: var(--color-blue);
}
