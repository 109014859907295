.large-hero {
  position: relative;
  text-shadow: 2px 1px var(--color-grey);
  max-height: 500px;
  overflow: hidden;
}
.large-hero--img {
  width: 100%;
}
.large-hero--content {
  position: absolute;
  left: 0;
  top: 50%;
  /* vertical alignment */
  transform: translateY(-50%);
  /* precise centering */
  text-align: center;
  padding: 10px;
  width: 100%;
}
@media (min-width: 768px) {
  .large-hero--content {
    left: 50%;
    /* horizontal alignment */
    transform: translate(-50%, -50%);
    /* precise centering */
    width: auto;
  }
}
.large-hero--title {
  font-weight: 300;
  color: var(--color-vltblue);
  font-family: var(--font-serif);
  margin-bottom: 0;
}
.large-hero--subtitle {
  font-weight: 300;
  color: var(--color-orange);
  margin-bottom: 0.5rem;
}
.large-hero--more-info {
  color: var(--color-white);
  font-weight: 300;
  border-top: 1px var(--color-ltgrey) solid;
  padding-top: 0.5rem;
  display: inline-block;
}
@media (min-width: 768px) {
  .large-hero--more-info {
    font-size: 1.6rem;
  }
}
