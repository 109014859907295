.kiley-image {
  display: none;
}

@media (min-width: 768px) {
  .kiley-image {
    display: inline;
    width: 40%;
    margin-left: 1rem;
    box-shadow: 2px 2px 10px 2px var(--color-shadow);
  }
}
